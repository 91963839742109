





































import Vue from 'vue'

/**  Model **/
import { IRutaModel } from '@/store/types'

/**  Service **/
import RutaService from '@/services/RutaService'

export default Vue.extend({
  data() {
    return {
      help: {
        title: 'Contactanos',
        subtitle: '',
        message: 'Déjanos un mensajito al interno y vámonos a vivir una increíble experiencia.'
      },

      num: '+573225359619',

      valid: true,
      record: {
        nombre: '',
        celular: '',
        email: '',
        ruta: '',
        checkbox: false
      },

      rules: {
        required: [(v: any) => !!v || 'Este campo es requerido'],
        email: [(v: any) => !!v || 'Este campo es requerido', (v: any) => /.+@.+\..+/.test(v) || 'El email debe ser válido']
      },

      records: [] as IRutaModel[]
    }
  },

  computed: {
    formRecord(): any {
      return this.$refs.formRecord as Vue & { validate: () => boolean; reset: () => void }
    }
  },

  mounted() {
    this.load()
  },

  methods: {
    async load() {
      this.$store.dispatch('app/showLoading')
      const record = (await RutaService.findAll()) as any
      this.records = record.filter((f: IRutaModel) => f.Estado !== 'Borrador')
      this.$store.dispatch('app/hideLoading')
    },

    validate() {
      const msg = `Hola,  mi nombre es ${this.record.nombre} y estoy interesado/a en vivir la experiencia Eco Caminantes  en  ${this.record.ruta} \n ¿ podrías ampliarme la información?`

      window.open(`https://wa.me/${this.num}?text=${encodeURI(msg)}`, '_blank')
      // this.$refs.formRecord.validate()
    },

    reset() {
      // this.$refs.formRecord.reset()
    },

    resetValidation() {
      // this.$refs.formRecord.resetValidation()
    }
  }
})
